<template>
    <nav>
        <Menu v-slot="{ open }">
            <menu-button
                class="bg-white align-bottom"
                @click="lockScroll(open)"
            >
                <span class="sr-only">Open menu</span>
                <menu-icon v-if="!open" class="h-6 w-6"></menu-icon>
                <x-icon v-if="open" class="h-6 w-6"></x-icon>
            </menu-button>
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-out"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0"
            >
                <menu-items
                    as="div"
                    class="fixed left-0 top-auto z-10 mt-1 block h-full w-full space-y-4 overflow-y-scroll overscroll-contain bg-white px-5 pb-10 pt-6 !outline-none"
                >
                    <Disclosure
                        as="div"
                        v-for="item in items"
                        v-slot="{ open }"
                        :default-open="item.group === (activeGroup ?? false)"
                    >
                        <DisclosureButton class="flex items-center">
                            <a
                                v-if="item.children.length === 0"
                                :href="item.uri"
                                class="flex items-center"
                                :class="item.class"
                                :title="item.title"
                                :target="item.target"
                            >
                                {{ item.label }}
                            </a>
                            <span v-else class="flex items-center">
                                {{ item.label }}
                                <chevron-right-icon
                                    class="ml-2 h-4 w-4"
                                    :class="open && 'rotate-90 transform'"
                                />
                            </span>
                        </DisclosureButton>
                        <transition
                            enter-active-class="transition duration-100 ease-out"
                            enter-from-class="transform scale-95 opacity-0"
                            enter-to-class="transform scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-out"
                            leave-from-class="transform scale-100 opacity-100"
                            leave-to-class="transform scale-95 opacity-0"
                        >
                            <DisclosurePanel
                                class="pt-4 focus:ring-0 focus:ring-offset-0"
                                v-if="item.children.length > 0"
                            >
                                <ul class="flex flex-col space-y-4">
                                    <li v-for="child in item.children">
                                        <a
                                            v-if="child.children.length === 0"
                                            :href="child.uri"
                                            class="flex items-center"
                                            :title="child.title"
                                            :target="child.target"
                                        >
                                            <span
                                                class="mr-2 inline-block h-5 w-5"
                                                :class="child.class"
                                            >
                                                <Component
                                                    v-if="child.icon"
                                                    :is="child.icon"
                                                />
                                            </span>
                                            {{ child.label }}
                                        </a>
                                        <Disclosure
                                            as="div"
                                            v-else
                                            v-slot="{ open }"
                                        >
                                            <DisclosureButton
                                                class="flex items-center"
                                            >
                                                <span
                                                    class="mr-2 inline-block h-5 w-5"
                                                    :class="child.class"
                                                    :title="child.title"
                                                >
                                                    <Component
                                                        v-if="child.icon"
                                                        :is="child.icon"
                                                    />
                                                </span>
                                                {{ child.label }}
                                                <chevron-right-icon
                                                    class="ml-2 h-4 w-4"
                                                    :class="
                                                        open &&
                                                        'rotate-90 transform'
                                                    "
                                                />
                                            </DisclosureButton>
                                            <transition
                                                enter-active-class="transition duration-100 ease-out"
                                                enter-from-class="transform scale-95 opacity-0"
                                                enter-to-class="transform scale-100 opacity-100"
                                                leave-active-class="transition duration-75 ease-out"
                                                leave-from-class="transform scale-100 opacity-100"
                                                leave-to-class="transform scale-95 opacity-0"
                                            >
                                                <DisclosurePanel
                                                    class="pl-6 pt-4"
                                                >
                                                    <ul
                                                        class="flex flex-col space-y-4"
                                                    >
                                                        <li
                                                            v-for="grandchild in child.children"
                                                        >
                                                            <a
                                                                :href="
                                                                    grandchild.uri
                                                                "
                                                                class="flex items-center"
                                                                :title="
                                                                    grandchild.title
                                                                "
                                                                :target="
                                                                    grandchild.target
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-2 inline-block h-5 w-5"
                                                                    :class="
                                                                        grandchild.class
                                                                    "
                                                                >
                                                                    <Component
                                                                        v-if="
                                                                            grandchild.icon
                                                                        "
                                                                        :is="
                                                                            grandchild.icon
                                                                        "
                                                                    />
                                                                </span>
                                                                {{
                                                                    grandchild.label
                                                                }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </DisclosurePanel>
                                            </transition>
                                        </Disclosure>
                                    </li>
                                </ul>
                            </DisclosurePanel>
                        </transition>
                    </Disclosure>
                    <ul class="flex flex-col space-y-4 pb-12">
                        <li><a href="/concierge">Let us help</a></li>
                    </ul>
                </menu-items>
            </transition>
        </Menu>
    </nav>
</template>

<script>
import lockScroll from '@/helpers/lockScroll';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue';
import {
    BeakerIcon,
    ChartPieIcon,
    ChevronRightIcon,
    ChipIcon,
    ClipboardCheckIcon,
    DesktopComputerIcon,
    FireIcon,
    GiftIcon,
    HeartIcon,
    MenuIcon,
    PuzzleIcon,
    SparklesIcon,
    XIcon,
} from '@heroicons/vue/outline';

export default {
    name: 'MobileNavigation',
    props: {
        items: {
            required: true,
            type: Array,
        },
        activeGroup: {
            required: false,
            type: String,
        },
    },
    components: {
        ChevronRightIcon,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
        MenuIcon,
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        XIcon,
        BeakerIcon,
        ChartPieIcon,
        ChipIcon,
        ClipboardCheckIcon,
        DesktopComputerIcon,
        FireIcon,
        GiftIcon,
        PuzzleIcon,
        SparklesIcon,
        HeartIcon,
    },
    methods: {
        lockScroll,
    },
};
</script>
