<template>
    <div>
        <div class="mb-5 flex flex-col space-y-1">
            <clearable-filter-header
                class="mb-1 flex items-center space-x-2"
                filter-key="ships_from"
                @filter-cleared="selectedOrigins = []"
                >Ships from country
            </clearable-filter-header>
            <span class="text-sm font-normal text-gray-400"
                >Only show events that ship from the selected countries</span
            >
        </div>

        <ul class="grid grid-cols-1 gap-y-3 text-sm at720:grid-cols-2">
            <li
                v-for="(country, idx) in origins"
                class="flex flex-row items-center space-x-4"
            >
                <input
                    :id="`modal-filter-shipping-origin-${idx}`"
                    @change="handleSelection(country.value, $event)"
                    type="checkbox"
                    :value="country.value"
                    class="rounded border-gray-300"
                    :checked="selectedOrigins.indexOf(country.value) > -1"
                />
                <label :for="`modal-filter-shipping-origin-${idx}`">{{
                    country.label
                }}</label>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ClearableFilterHeader from 'components/ProductList/ProductFilter/ModalFilters/ClearableFilterHeader.vue';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { filterOptions, queueFilter } = useCollectionFilters();
const origins = filterOptions.shippingCountries;
const selectedOrigins = ref(props.current ? [...props.current] : []);

const handleSelection = (country, event) => {
    if (event.target.checked) {
        selectedOrigins.value.push(country);
        selectedOrigins.value.sort();
    } else {
        try {
            let index = selectedOrigins.value.indexOf(country);
            if (index !== -1) {
                selectedOrigins.value.splice(index, 1);
            }
        } catch (e) {}
    }
    queueFilter('ships_from', selectedOrigins.value);
};
</script>

<style scoped></style>
