<template>
    <div>
        <div class="mb-6 flex flex-col gap-y-1">
            <h3 class="text-lg font-semibold">Food</h3>
            <span class="text-sm font-normal text-gray-400"
                >Only show events that include food or is available for
                purchase.</span
            >
        </div>

        <div class="flex flex-row items-center gap-x-2">
            <Switch
                v-model="hasFilter"
                :class="hasFilter ? 'bg-black' : 'bg-gray-300'"
                class="relative inline-flex h-5 w-10 items-center rounded-full"
            >
                <span
                    :class="
                        hasFilter ? 'translate-x-[1.4rem]' : 'translate-x-0.5'
                    "
                    class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                />
            </Switch>
            <label @click="hasFilter = !hasFilter" class="text-sm font-medium"
                >Only show events that include food</label
            >
        </div>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import { Switch } from '@headlessui/vue';
import { ref, watch } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { queueFilter } = useCollectionFilters();
const hasFilter = ref(!!props.current);

watch(hasFilter, (newValue) => {
    queueFilter('food', newValue || null);
});
</script>
