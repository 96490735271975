<template>
    <div>
        <clearable-filter-header
            class="mb-6 flex items-center space-x-2"
            filter-key="dietary_substitutions"
            @filter-cleared="selectedSubstitutions = []"
            >Food &amp; drink substitutions</clearable-filter-header
        >

        <ul class="grid grid-cols-1 gap-y-3 text-sm at720:grid-cols-2">
            <li
                v-for="(substitution, idx) in dietarySubstitutions"
                class="flex flex-row items-center space-x-4"
            >
                <input
                    :id="`modal-filter-substitution-${idx}`"
                    @change="handleSelection(substitution.value, $event)"
                    type="checkbox"
                    :value="substitution.value"
                    class="rounded border-gray-300"
                    :checked="
                        selectedSubstitutions.indexOf(substitution.value) > -1
                    "
                />
                <label :for="`modal-filter-substitution-${idx}`">{{
                    substitution.label
                }}</label>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { useCollectionFilters } from '@/composables/useCollectionFilters';
import ClearableFilterHeader from 'components/ProductList/ProductFilter/ModalFilters/ClearableFilterHeader.vue';
import { ref } from 'vue';

const props = defineProps({
    current: {
        required: false,
        default: () => null,
    },
});

const { filterOptions, queueFilter } = useCollectionFilters();
const dietarySubstitutions = filterOptions.dietarySubstitutions;
const selectedSubstitutions = ref(props.current ? [...props.current] : []);

const handleSelection = (substitution, event) => {
    if (event.target.checked) {
        selectedSubstitutions.value.push(substitution);
        selectedSubstitutions.value.sort();
    } else {
        try {
            let index = selectedSubstitutions.value.indexOf(substitution);
            if (index !== -1) {
                selectedSubstitutions.value.splice(index, 1);
            }
        } catch (e) {}
    }
    queueFilter('dietary_substitutions', selectedSubstitutions.value);
};
</script>

<style scoped></style>
