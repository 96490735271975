export function formatCurrency(amount, currency = 'USD') {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        trailingZeroDisplay: 'stripIfInteger',
    });
    return formatter.format(amount);
}
