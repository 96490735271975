<template>
    <div v-if="reviews.length">
        <div class="mb-4 flex items-center space-x-2">
            <h3
                class="scroll-m-6 text-xl font-medium leading-6 text-black"
                id="reviews-header"
            >
                Reviews
            </h3>
            <new-badge v-if="product.isNew"></new-badge>
            <reviews-badge
                class="leading-6.5 flex"
                :rating-score="product.ratingScore"
                :rating-count="product.ratingCount"
                :rating-type="product.ratingType"
            ></reviews-badge>
        </div>
        <div
            v-if="product.ratingType === 'host'"
            class="py-3 text-sm text-gray-400"
        >
            We haven't received enough reviews for this
            {{ product.isProduct ? 'listing' : 'event' }}. The following are
            reviews from other
            {{ product.isProduct ? 'listings' : 'events' }} this host has listed
            with Elevent.
        </div>
        <dl class="mb-6 mt-4 space-y-3 at1024:mb-8">
            <div v-for="i in 5" class="flex items-center text-sm">
                <dt class="flex flex-1 items-center">
                    <p class="w-3 font-medium text-black">
                        {{ 6 - i }}<span class="sr-only"> star reviews</span>
                    </p>
                    <div
                        aria-hidden="true"
                        class="ml-1 flex flex-1 items-center"
                    >
                        <star-icon
                            class="h-4 w-4 flex-shrink-0 text-orange"
                        ></star-icon>

                        <div class="relative ml-3 flex-1">
                            <div
                                class="h-3 rounded-full border border-gray-300 bg-gray-300"
                            ></div>

                            <div
                                v-if="ratings[6 - i] > 0"
                                v-bind:style="{
                                    width: ratings[6 - i] + '%',
                                }"
                                class="absolute inset-y-0 rounded-full border border-orange bg-orange"
                            ></div>
                        </div>
                    </div>
                </dt>
                <dd
                    class="ml-3 w-10 text-right text-sm tabular-nums text-black"
                >
                    {{ ratings[6 - i] }}%
                </dd>
            </div>
        </dl>
        <div class="mb-4 text-right">
            <select
                v-model="sort"
                @change="getReviews(1)"
                :disabled="reviewsLoading"
                id="reviews-sort"
                class="rounded border-gray-300 py-2 pl-3 pr-10 text-sm"
            >
                <option
                    v-for="(label, value) in sorts"
                    :key="value"
                    :value="value"
                    class="px-2 py-8"
                >
                    {{ formatSortLabel(value) }}
                </option>
            </select>
        </div>
        <ol
            class="grid gap-8 at720:grid-cols-2 at1440:gap-16"
            :class="{ 'opacity-25': reviewsLoading }"
        >
            <li v-for="review in reviews" class="text-[15px]">
                <h3 class="font-semibold text-gray-850">
                    {{ review.author_name }}
                    <time class="font-normal text-gray-600"
                        ><span v-if="review.author_name" aria-hidden="true"
                            >- </span
                        >{{ review.review_date_relative_text }}</time
                    >
                </h3>
                <p class="mt-2">
                    <span
                        class="rating-stars"
                        :class="'stars-' + review.rating"
                    >
                        <span class="sr-only"
                            >Rated: {{ review.rating }} stars</span
                        >
                    </span>
                </p>
                <p class="mt-2 text-gray-600">
                    {{ review.comment }}
                </p>
                <p
                    v-if="showProductName && review.listing?.name"
                    class="mt-2 text-sm font-semibold"
                >
                    {{ review.listing.name }}
                </p>
            </li>
        </ol>
        <simple-pagination
            class="mt-8 flex justify-center at1024:mt-12"
            :current-page="page"
            :pages="pageCount"
            @page-next-requested="
                getReviews(page + 1);
                scrollToReviews();
            "
            @page-prev-requested="
                getReviews(page - 1);
                scrollToReviews();
            "
        ></simple-pagination>
        <component
            :is="'script'"
            v-html="jsonLd"
            type="application/ld+json"
        ></component>
    </div>
</template>

<script setup>
import { useReviewFetch } from '@/composables/useReviewFetch';
import { ServiceRouter } from '@/service-router';
import { StarIcon } from '@heroicons/vue/solid';
import axios from 'axios';
import ReviewsBadge from 'components/Global/ReviewsBadge.vue';
import NewBadge from 'components/NewBadge.vue';
import SimplePagination from 'components/SimplePagination.vue';
import { inject, onMounted, ref } from 'vue';
import VueScrollTo from 'vue-scrollto';

const product = inject('product');

const props = defineProps({
    showProductName: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const reviewsAndRatingsEndpointBasePath = [
    'webapi',
    product.ratingType === 'host' ? 'hosts' : 'listings',
    product.ratingType === 'host' ? product.host.uuid : product.uuid,
].join('/');

const serviceRouter = new ServiceRouter();
const {
    reviews,
    reviewsLoading,
    getReviews,
    page,
    pageCount,
    sorts,
    sort,
    formatSortLabel,
    jsonLd,
} = useReviewFetch(reviewsAndRatingsEndpointBasePath + '/reviews', {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': product.uuid,
    name: product.name,
    sku: product.sku,
});
const ratings = ref([]);

const getRating = async () => {
    axios
        .get(
            serviceRouter.catalogUrl(
                reviewsAndRatingsEndpointBasePath + '/ratings'
            )
        )
        .then((response) => {
            let data = response.data.data;
            let count = data['count'] ?? 0;
            let _ratings = [];
            for (let i = 1; i <= 6; i++) {
                if (count > 0) {
                    let scoreCount = data[i] ?? 0;
                    _ratings[i] = {
                        score: i,
                        total: scoreCount,
                        percentage: (scoreCount / count) * 100,
                        rounded_percentage: Math.floor(
                            (scoreCount / count) * 100
                        ),
                    };
                } else {
                    _ratings[i] = 0;
                }
            }
            // Check if total is less than 100%
            let diff =
                100 -
                _ratings
                    .map((r) => r.rounded_percentage)
                    .reduce((sum, n) => {
                        return sum + n;
                    });
            if (diff > 0) {
                // Sort by decimal place descending
                let sorted = _ratings.sort(
                    (a, b) =>
                        b.percentage -
                        b.rounded_percentage -
                        (a.percentage - a.rounded_percentage)
                );
                // Add the diff to the top x scores
                sorted.map((n, i) => {
                    i < diff ? n.rounded_percentage++ : n.rounded_percentage;
                    return n;
                });
            }
            _ratings.forEach((n) => {
                ratings.value[n.score] = n.rounded_percentage;
            });
        });
};
const scrollToReviews = () => {
    VueScrollTo.scrollTo('#reviews-sort', 200, {
        force: false,
        offset: -10,
    });
};

onMounted(() => {
    if (!product.isLeadForm) {
        getReviews();
        getRating();
    }
});
</script>
