<template>
    <span>
        <span
            class="ml-1 flex items-center space-x-1.5 whitespace-nowrap rounded-md bg-orange px-2.5 py-1 font-semibold text-white"
        >
            <span>New</span>
        </span>
    </span>
</template>

<script setup></script>

<style scoped></style>
